<template>
  <div >
    <!-- web端 start-->
    <div class="layout-web">
      <top-bar id="Head"/>
<!--      <div style="height: calc(100vh - 110px);overflow: auto">-->
        <router-view />
<!--        <bottom />-->
<!--      </div>-->

    </div>
    <!-- web端 end -->
  </div>
</template>

<script>
import TopBar from "@/layout/components/TopBar";
import bottom from "@/layout/components/bottom";

export default {
  name: "index",
  components: {
    TopBar,
    bottom
  },
  data(){
    return{
    }
  },
  mounted() {
    let Head = document.getElementById('Head')
    window.onscroll = ()=>{
      // 获取滚动距离
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top != 0) {
        Head.style = "position: fixed;top: 0;"
      }else{
        Head.style = "position: static;"
      }
    }
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.layout-web {
  min-width: 1250px;
}
#Head {
  z-index: 999999999;
}
</style>
