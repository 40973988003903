<template>
  <div class="top-bar">
    <div class="flex align-items-center justify-content-between h-100">
      <div class="top-bar-logo">
        <img src="../../assets/images/logo/logo-green.png" height="40"/>
      </div>
      <div class="top-bar-menu flex align-items-center justify-content-around">
        <div class="top-bar-menu-item pointer ml10 mr10" v-for="item in menu"
             @click="activeItem(item.path)"
             :class="{'top-bar-menu-item-title-active': active == item.path}">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      activeIndex2: '1',
      active: 'Home',
      menu: [
        {
          title: '首页',
          path: 'Home',
        },
        {
          title: '产品中心',
          path: 'ProductCenter',
        },
        {
          title: '解决方案',
          path: 'Solution',
        },
        {
          title: '关于我们',
          path: 'About',
        },
        {
          title: '加入我们',
          path: 'Join',
        }
      ]
    }
  },
  mounted() {
    this.active = this.$route.name
  },
  watch: {
    $route() {
      this.active = this.$route.name
    },
  },
  methods: {
    activeItem(val) {
      console.log(this.$route);
      this.active = val
      if (this.$route.name == val) {
        this.$router.go(0)
      } else {
        this.$router.push(val)
      }

      //
    },
  }
}
</script>

<style lang="scss" scoped>
.top-bar {
  height: 60px;
  width: 100%;
  background-color: #262626;
}

.top-bar-logo {
  padding-left: 10vw;
}

.top-bar-menu {
  width: 800px;
  padding-right: 10vw;
}

.top-bar-menu-item {
  font-size: 15px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #FFFFFF;
  padding: 9px 2px;
  border-bottom: 3px solid transparent;

  &:hover {
    cursor: pointer;
    color: #47B66E;
    border-bottom: 3px solid #47B66E;
  }
}

.top-bar-menu-item-title-active {
  color: #47B66E;
  border-bottom: 3px solid #47B66E;
}


.top-bar-menu-item-sub {
  padding: 10px 70px;
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  color: #2c4198;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0);

  &:hover {
    cursor: pointer;
    color: #2c4198;
    border-bottom: 2px solid #2c4198;
  }
}
</style>
