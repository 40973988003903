<template>
  <div>
    <div class="bottom-msg flex align-items-center justify-content-center">
      <div class="flex align-items-center justify-content-around" style="width: 1200px">
        <div class="flex flex-column align-items-center justify-content-center" >
          <img src="../../assets/images/code/officialAccount.png" height="133" width="133"/>
          <div class="bottom-title">
            扫一扫关注微车检公众号
          </div>
        </div>
        <div class="flex flex-column align-items-center justify-content-center" >
          <img src="../../assets/images/code/applet.png" height="133" width="133"/>
          <div class="bottom-title">
            扫一扫关注微车检小程序
          </div>
        </div>
        <div class="bottom-title">
          <div class="bottom-title-item">联系电话： 0771-5623677</div>
          <div class="bottom-title-item">邮箱： weichejian@qq.com</div>
          <div class="bottom-title-item flex">
            <div>示范基地： </div>
            <div>中国（广西）自由贸易试验区南宁片区<br>五象大道403号富雅国际金融中心G1栋2518</div>
          </div>
          <div class="bottom-title-item flex">
            <div>研发中心： </div>
            <div>中国（广西）自由贸易试验区南宁片区<br>
              五象大道403号富雅国际金融中心G1栋2518</div>
          </div>
          <div class="bottom-title-item">研发中心： 0771-5623677</div>
          <div class="bottom-title-item">网址： www.weichejian.com</div>
        </div>
      </div>
      <div></div>
    </div>
    <div class="bottom-copyright flex flex-column justify-content-center align-items-center">
      <span>版权信息   Copyright [dates] by [author/owner] 备案信息	 浙ICP备12345678号</span>
    </div>
  </div>

</template>

<script>
export default {
  name: "bottom"
}
</script>

<style lang="scss" scoped>
.bottom-msg {
  background: url("../../assets/images/background/bottom.png");
  background-size: 100% 100%;
  width: 100%;
  height: 308px;
  .bottom-title {
    font-size: 14px;
    font-family: HarmonyOS_Sans_SC;
    font-weight: 400;
    color: #FFFFFF;
    padding: 15px 0;
    .bottom-title-item {
      padding: 8px;
    }
  }
}
.bottom-copyright {
  height: 35px;
  background: #262626;
  font-size: 16px;
  font-family: HarmonyOS_Sans_SC_Light;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 37px;
}
</style>
