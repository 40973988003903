import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layout */
import Layout from '@/layout'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/Home',
    children: [
      {
        path: 'Home',
        component: (resolve) => require(['@/views/home'], resolve),
        name: 'Home',
        meta: {
          title: '首页', icon: 'dashboard', affix: true, content: {
            keywords: '首页',
            description: '首页',
          }
        }
      },
      {
        path: 'ProductCenter',
        component: (resolve) => require(['@/views/productCenter'], resolve),
        name: 'ProductCenter',
        meta: {
          title: '产品中心', icon: 'dashboard', affix: true, content: {
            keywords: '产品中心',
            description: '产品中心',
          }
        }
      },
      {
        path: 'Solution',
        component: (resolve) => require(['@/views/solution'], resolve),
        name: 'Solution',
        meta: {
          title: '解决方案', icon: 'dashboard', affix: true, content: {
            keywords: '解决方案',
            description: '解决方案',
          }
        }
      },
      {
        path: 'About',
        component: (resolve) => require(['@/views/about'], resolve),
        name: 'About',
        meta: {
          title: '关于我们', icon: 'dashboard', affix: true, content: {
            keywords: '关于我们',
            description: '关于我们',
          }
        }
      },
      {
        path: 'Join',
        component: (resolve) => require(['@/views/join'], resolve),
        name: 'Join',
        meta: {
          title: '加入我们', icon: 'dashboard', affix: true, content: {
            keywords: '加入我们',
            description: '加入我们',
          }
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
